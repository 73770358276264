import {mapActions, mapGetters, mapMutations} from "vuex";
import {email, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
import secondSelect from '@/components/select/index.vue'
import moment from 'moment'
import 'moment/locale/uk'
import 'moment/locale/ru'

export default {
  name: "add-card",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        number: '',
        exp_month:'',
        exp_year:'',
        cvv:'',
      },
      disable:false,
      validationErrors: {},
    }

  },
  components:{
    StripeElements,
    StripeElement,
    secondSelect
  },
  validations: {
    payload: {
      number: {
        required,
      },
      exp_month: {
        required,
      },
      exp_year: {
        required,
      },
      cvv: {
        required,
      },
    }
  },
  computed: {
    ...mapGetters({}),
    years() {
      const arr = [];
      const currentYear = new Date().getFullYear()
      for (let i = currentYear - 5; i < currentYear + 20; i++) {
        arr.push(i)
      }
      return arr
    },
    months() {
      return moment.months().map((e, index) => {
        return {
          id: index + 1,
          name: e
        }
      });
    },
    cardNumberErrors() {
      let error = [];
      if (!this.$v.payload.number.$dirty) {
        return error;
      }
      if (!this.$v.payload.number.required) {
        error.push(this.$t('validationRequired'))
      }
      return error;
    },
    cardExpMonthsErrors() {
      let error = [];
      if (!this.$v.payload.exp_month.$dirty) {
        return error;
      }
      if (!this.$v.payload.exp_month.required) {
        error.push(this.$t('validationRequired'))
      }
      return error;
    },
    cardExpYearErrors() {
      let error = [];
      if (!this.$v.payload.exp_year.$dirty) {
        return error;
      }
      if (!this.$v.payload.exp_year.required) {
        error.push(this.$t('validationRequired'))
      }
      return error;
    },
    cardCvvErrors() {
      let error = [];
      if (!this.$v.payload.cvv.$dirty) {
        return error;
      }
      if (!this.$v.payload.cvv.required) {
        error.push(this.$t('validationRequired'))
      }
      return error;
    },
  },
  methods: {
    ...mapMutations({
      changePopupCardPay: `order/CHANGE_POPUP_CARD_PAY`,
    }),
    ...mapActions({
      addCard: `payment/ADD_CARD`,
      fetchCardList: `payment/GET_CARD_LIST`
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.disable = true
        let obj = {}
        obj.cvv = this.payload.cvv
        obj.number = this.payload.number
        obj.exp_month = this.payload.exp_month.id
        obj.exp_year = this.payload.exp_year
        this.addCard(obj).then(() => {
          setTimeout(()=>{
            this.disable = false
          },500)
          this.fetchCardList()
          this.changePopupCardPay(false)
          this.$toasted.success(this.$t('cardAdded'));
        }).catch(error => {
          console.log(error);
          if (error.response.status === 400) {
            this.$toasted.error(this.$t('cardNumberIncorrect'));
          }
          setTimeout(()=>{
            this.disable = false
          },500)
        });
      }
    },
  }
}